<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import copy from 'copy-to-clipboard'
import { Ref, computed, inject, onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { RouteLocationNormalizedLoaded, Router, RouterView, useRoute, useRouter } from 'vue-router'

import AccountMenu from '@ankor-io/blocks/components/AccountMenu.vue'
import { modalHelper } from '@ankor-io/blocks/components/modals/modalHelper'
import { showAwaitToast, showErrorToast, showInfoToast } from '@ankor-io/blocks/components/toast'
import Banner from '@ankor-io/blocks/layouts/Banner.vue'
import OneColumnWithHeader from '@ankor-io/blocks/layouts/OneColumnWithHeader.vue'
import { useAppDispatcher } from '@ankor-io/common/lang/events'
import { Events } from '@ankor-io/common/proposal/Events'
import { EditableProposal, JsonProposal, ProposalItem } from '@ankor-io/common/proposal/Proposal'
import { Trip } from '@ankor-io/common/trip/Trip'
import { OutlineEye, OutlineShare } from '@ankor-io/icons/outline'
import { SolidChevronDown, SolidColorSwatch, SolidHome, SolidRoute, SolidYacht } from '@ankor-io/icons/solid'

import Loader from '@/components/Loader.vue'
import { AuthenticationContext } from '@/iam/types'
import { StateService, useStateServiceProvider } from '@/services/StateService'
import { useProposalService } from '@/services/proposal/ProposalService'
import { EditableProposalDeserializer } from '@/services/proposal/deserializer/EditableProposalDeserializer'
import { useSaveStatusStore } from '@/stores/saveStatus/saveStatus.store'

const props = defineProps<{
  uri?: string
  items?: string
}>()

const canAccess: Ref<boolean> = ref(true)
const bannerOpen: Ref<boolean> = ref(false)
const authenticationContext: AuthenticationContext = inject('authenticationContext')!
const route: RouteLocationNormalizedLoaded = useRoute()
const router: Router = useRouter()
const previewModalHelper = modalHelper()
const proposalService = useProposalService()
const editablePresentation: Ref<EditableProposal | null> = ref(null)
const dispatcher = useAppDispatcher().get()
const queryStringItem = ref<string | null>(null)
const saveStatusStore = useSaveStatusStore()

const stateService: StateService = await useStateServiceProvider(inject).then((_stateServiceProvider) => {
  return _stateServiceProvider.get()
})

/**
 * Listener callback for the {@link Events.SYNC} app event
 */
const synchronize = () => {
  stateService.getClient(props.uri!).onChanges(editablePresentation.value!.toJson())
  // isEditSaved.value = stateService.getClient(props.uri).isSaved()
}

onBeforeMount(() => {
  if (route.name === 'trips-editor-presentation-new') {
    createNewPresentation()
  } else if (route.name === 'trips-editor-presentation-duplicate' && props.uri) {
    duplicate(props.uri)
  }
})

onMounted(() => {
  if (route.path.includes('/trips/editor/presentation/edit') && props.uri) {
    dispatcher.addEventListener(Events.SYNC, synchronize)
    registerPresentationObserver(props.uri)
  }
})

onBeforeUnmount(() => {
  if (props.uri) {
    stateService.getClient(props.uri).unRegisterObserver(presentationEditorObserver)
  }
  dispatcher.removeEventListener(Events.SYNC, synchronize)
})

watch(editablePresentation, (newValue, oldValue) => {
  if (oldValue === null && newValue !== null) {
    // Note - queryStringItem will only be defined during the create presentation flow.
    if (queryStringItem) {
      const item = JSON.parse(queryStringItem.value as string)
      const proposalItem: ProposalItem = {
        type: item.type,
        uri: item.uri,
        item,
      } as ProposalItem

      editablePresentation.value?.addItem(proposalItem)
      stateService.getClient(props.uri!).onChanges(editablePresentation.value!.toJson())
    }
  }
})

const registerPresentationObserver = (uri: string) => {
  stateService.getClient(uri).registerObserver(presentationEditorObserver)
  stateService.onForbidden(uri, () => {
    canAccess.value = false
  })
}

const presentationEditorObserver = async () => {
  // skip server edits when in pause mode
  if (editablePresentation.value?.isInitializing()) {
    return
  }
  const document = stateService.getClient(props.uri!).getContent() as JsonProposal

  editablePresentation.value?.destroy()
  editablePresentation.value = EditableProposalDeserializer.deserialize(document)

  saveStatusStore.isSaved = stateService.getClient(props.uri!).isSaved()
}

/**
 * Share and preview should be disabled unless user has added at least one item (A route or Yacht)
 * has been added to the selection and has reached the review step
 */
const shareAndPreviewDisabled = computed(() => {
  // TODO: Wire this up - for now, return disabled
  return true

  // If there is nothing don't enable share
  // if (!props.jsonProposal?.document.slides.length) {
  //   return false
  // }

  // return props.jsonProposal.document.slides.some((slide: any) => {
  //   return slide.sections.some(
  //     (section: any) => section.state === EditableSectionState.NEEDS_INIT && section.type !== SectionType.NAV_BAR,
  //   )
  // })
})

// Creates a new presentation and navigates to the edit page
const createNewPresentation = async () => {
  showInfoToast('Creating new presentation...')

  const token = await authenticationContext.getToken()
  const res: Response = await fetch('/api/presentation/new', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  if (res.ok) {
    const { uri }: { uri: string } = await res.json()

    /*
      Process to pre popoulate an empty presentation with a yacht or route
      1. From the dashboard page, send the Algolia index item as a query string "item"
      2. During create presentation, grab the item from url and save it into a vue ref
      3. After Diffsync connection is opened, update the presention with the item (done in the watcher)
    */
    if (route.query.item) {
      queryStringItem.value = route.query.item as string
    }

    router.replace({ name: 'trips-editor-presentation-edit-yachts', params: { uri } })
    dispatcher.addEventListener(Events.SYNC, synchronize)
    registerPresentationObserver(uri)
  } else {
    showErrorToast('Failed to create new presentation')
    console.debug('Failed to create new presentation', res)
  }
}

// Duplicates a presentation and navigates to the edit page
const duplicate = async (fromUri: string) => {
  showInfoToast('Duplicating presentation...')

  const token = await authenticationContext.getToken()
  const res: Response = await fetch(`/api/presentation/duplicate/${fromUri}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  if (res.ok) {
    const { uri }: { uri: string } = await res.json()
    router.replace({
      name: 'trips-editor-presentation-edit-routes',
      params: { uri },
      query: { addItem: route.query?.addItem },
    })
    dispatcher.addEventListener(Events.SYNC, synchronize)
    registerPresentationObserver(uri)
  } else {
    showErrorToast('Failed to duplicate presentation')
    console.debug('Failed to duplicate presentation', res)
  }
}

const share = (domain: string, uri: string) => {
  // hide the modal
  previewModalHelper.hide()
  // share & show toast
  showAwaitToast(
    proposalService.share(uri),
    `Creating link to share <em>${editablePresentation.value?.internalName}</em>...`,
    `Link created and copied to your clipboard.`,
    `<em>${editablePresentation.value?.internalName}</em>, shareable link could not be copied.`,
  ).then((trip: Trip) => {
    copy(`${domain}/${trip.bookingId}/proposal`)
  })
}

const updateInternalName = (ev: Event): void => {
  const target = ev.target as HTMLInputElement
  const { value } = target
  if (value === editablePresentation.value!.internalName) {
    return
  }
  ;(editablePresentation.value! as any).internalName = value
  // skip sending server changes when in pause mode
  if (editablePresentation.value?.isInitializing()) {
    return
  }

  // TODO: update internal name in local storage so it can be used in dashboard?
  if (props.uri) {
    stateService.getClient(props.uri).onChanges(editablePresentation.value!.toJson())
    saveStatusStore.isSaved = stateService.getClient(props.uri).isSaved()
  }
}

const updatePresentation = (updatedPresentation: JsonProposal) => {
  if (!props.uri) {
    return
  }
  stateService.getClient(props.uri).onChanges(updatedPresentation)
}
</script>
<template>
  <div>
    <Banner v-if="bannerOpen" @close="bannerOpen = false">
      <!-- p>Meet us at <strong>Cairns</strong>!</p -->
    </Banner>
    <OneColumnWithHeader
      :class-names="
        bannerOpen
          ? {
              content: 'h-[calc(100vh-2.75rem)]',
            }
          : undefined
      "
    >
      <template #header>
        <div
          class="sticky top-0 z-40 flex justify-between h-16 shrink-0 items-center gap-x-2 sm:gap-x-6 lg:gap-x-4 border-b shadow-sm px-2 sm:px-6 lg:px-8 border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-800"
        >
          <!-- nunununununununun -->
          <!--   Breadcrumbs      -->
          <!-- nunununununununun -->
          <div class="min-w-fit">
            <nav>
              <ol class="flex flex-row flex-nowrap shrink-0 items-center">
                <li class="mr-2">
                  <RouterLink
                    aria-current="page"
                    class="text-sm font-medium hover:fill-primary-600 dark:hover:fill-primary-500"
                    :to="{ name: 'trips-library-presentations' }"
                  >
                    <SolidHome
                      class="w-4 h-4 transition-all hover:fill-primary-600 hover:stroke-primary-600 dark:hover:fill-primary-500 dark:hover:stroke-primary-500 fill-gray-500 stroke-gray-500 dark:fill-gray-400 dark:stroke-gray-400"
                    />
                  </RouterLink>
                </li>
                <li class="hidden lg:block mr-2 text-sm text-gray-500 dark:text-gray-400">Presentations</li>
                <li class="hidden lg:block mr-2 text-sm text-gray-500 dark:text-gray-400">/</li>
                <li>
                  <input
                    type="text"
                    class="w-16 md:w-36 h-8 text-nowrap p-0 overflow-hidden lg:w-52 border-none rounded-md text-sm bg-transparent text-ellipsis focus:border text-gray-700 dark:text-gray-300 focus:border-primary-600 dark:focus:border-primary-500"
                    :value="editablePresentation?.internalName"
                    @blur="updateInternalName($event)"
                  />
                </li>
              </ol>
            </nav>
          </div>

          <!-- nunununununununun -->
          <!--       Views       -->
          <!-- nunununununununun -->
          <ul
            v-if="props.uri"
            class="hidden md:flex flex-nowrap -mb-px text-center font-medium text-sm text-gray-500 dark:text-gray-400"
          >
            <li>
              <RouterLink
                class="inline-flex items-center justify-center p-2 rounded-t-lg group transition-colors"
                :to="{ name: 'trips-editor-presentation-edit-yachts', params: { uri: props.uri } }"
                :class="[
                  $route.name === 'trips-editor-presentation-edit-yachts'
                    ? 'active text-primary-600 dark:text-primary-500'
                    : 'hover:text-gray-600 dark:hover:text-gray-400 dark:text-gray-500',
                ]"
              >
                <SolidYacht class="size-4 me-2" />Yacht
              </RouterLink>
            </li>
            <li>
              <RouterLink
                class="inline-flex items-center justify-center p-2 rounded-t-lg group transition-colors"
                :to="{ name: 'trips-editor-presentation-edit-routes', params: { uri: props.uri } }"
                :class="[
                  $route.name === 'trips-editor-presentation-edit-routes'
                    ? 'active text-primary-600 dark:text-primary-500'
                    : 'hover:text-gray-600 dark:hover:text-gray-400 dark:text-gray-500',
                ]"
              >
                <SolidRoute class="size-4 me-2" />Route
              </RouterLink>
            </li>
            <li>
              <RouterLink
                class="inline-flex items-center justify-center p-2 rounded-t-lg group"
                :to="{ name: 'trips-editor-presentation-edit-design', params: { uri: props.uri } }"
                :class="[
                  $route.name === 'trips-editor-presentation-edit-design'
                    ? 'active text-primary-600 dark:text-primary-500'
                    : 'hover:text-gray-600 dark:hover:text-gray-400 dark:text-gray-500',
                ]"
              >
                <SolidColorSwatch class="size-4 me-2" />Design
              </RouterLink>
            </li>
          </ul>
          <select
            class="md:hidden h-11 z-20 border-1 rounded-lg text-xs focus:ring-0 bg-white dark:bg-gray-700 border-gray-200 dark:border-gray-600 focus:border-gray-500 focus-within:border-gray-500 dark:focus:border-gray-500 dark:focus-within:border-gray-500"
            @change="
              $router.push({ name: ($event.currentTarget! as HTMLInputElement).value, params: { uri: props.uri } })
            "
          >
            <option class="" value="trips-editor-presentation-edit-routes">Routes</option>
            <option class="" value="trips-editor-presentation-edit-yachts">Yachts</option>
            <option class="" value="trips-editor-presentation-edit-design">Design</option>
          </select>

          <div class="flex items-center gap-x-2">
            <!-- Diff sync saved state -->
            <span
              class="w-12 font-medium text-xs"
              :class="saveStatusStore.isSaved ? 'text-gray-900 dark:text-gray-50' : 'text-gray-500 dark:text-gray-400'"
            >
              {{ saveStatusStore.isSaved ? 'Saved' : 'Saving...' }}
            </span>
            <!-- nunununununununun -->
            <!--    Button Group   -->
            <!-- nunununununununun -->
            <div class="flex items-center gap-x-2">
              <!-- Preview -->
              <span :class="{ 'cursor-not-allowed': shareAndPreviewDisabled }">
                <RouterLink
                  target="_blank"
                  class="transition-colors hidden lg:flex items-center gap-x-2 font-medium rounded-lg text-sm px-5 py-2.5 text-center border border-gray-200 dark:border-gray-600"
                  :to="{ name: 'trips-preview', params: { uri: encodeURIComponent(props.uri!) } }"
                  :class="
                    shareAndPreviewDisabled
                      ? 'pointer-events-none text-white bg-gray-200 dark:bg-gray-400'
                      : 'cursor-pointer focus:ring-4 focus:outline-none text-gray-900 dark:text-white bg-white dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700 focus:bg-gray-300 dark:focus:bg-gray-700'
                  "
                >
                  <OutlineEye class="size-4 shrink-0" />
                  Preview
                </RouterLink>
              </span>

              <!-- Share -->
              <button
                class="transition-all hidden lg:flex items-center gap-x-2 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center focus:ring-blue-300 text-white hover:text-white"
                :class="
                  shareAndPreviewDisabled
                    ? 'cursor-not-allowed bg-gray-200 dark:bg-gray-400'
                    : 'cursor-pointer bg-primary-600 dark:bg-primary-500 hover:bg-blue-500 dark:hover:bg-primary-800'
                "
                :disabled="shareAndPreviewDisabled"
                @click="share($config.WAVE_URL, props.uri!)"
              >
                <OutlineShare class="size-4 shrink-0" />
                Share
              </button>

              <div class="lg:hidden flex gap-x-px">
                <button
                  type="button"
                  class="w-full sm:w-auto justify-center transition-colors font-medium rounded-l-lg text-xs px-2 md:px-5 py-2.5 text-center inline-flex items-center"
                  :class="
                    shareAndPreviewDisabled
                      ? 'pointer-events-none text-white bg-gray-200 dark:bg-gray-400'
                      : 'cursor-pointer focus:ring-4 focus:outline-none text-white bg-primary-600 dark:bg-primary-500 hover:bg-primary-800 dark:hover:bg-primary-800 focus:ring-primary-300 dark:focus:ring-primary-300  dark:text-white '
                  "
                  @click="share($config.WAVE_URL, props.uri!)"
                >
                  Share
                </button>
                <Menu as="div" class="relative inline-block text-left">
                  <MenuButton
                    class="transition-colors flex rounded-r-lg px-2 md:px-5 py-2.5 items-center"
                    :class="
                      shareAndPreviewDisabled
                        ? 'text-white bg-gray-200 dark:bg-gray-400'
                        : 'cursor-pointer focus:ring-4 focus:outline-none text-white bg-primary-600 dark:bg-primary-500 hover:bg-primary-800 dark:hover:bg-primary-800 focus:ring-primary-300 dark:focus:ring-primary-300  dark:text-white '
                    "
                    :disabled="shareAndPreviewDisabled"
                  >
                    <span class="sr-only">Open options</span>
                    <SolidChevronDown class="size-5" aria-hidden="true" />
                  </MenuButton>
                  <transition
                    enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                  >
                    <MenuItems
                      class="absolute border right-0 z-50 mt-2 py-1 w-24 md:w-36 origin-top-right rounded-md shadow-lg ring-1 ring-opacity-5 focus:outline-none bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-600 ring-black"
                    >
                      <MenuItem>
                        <RouterLink
                          target="_blank"
                          class="flex transition-all gap-x-2 items-center cursor-pointer px-2 md:px-3 py-2 bg-white dark:bg-gray-800 hover:bg-gray-100 hover:dark:bg-gray-700 text-gray-500 dark:text-gray-400"
                          :to="{ name: 'trips-preview', params: { uri: props.uri } }"
                        >
                          <span class="text-sm">Preview</span>
                        </RouterLink>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>

              <!-- Profile -->
              <AccountMenu
                :name="authenticationContext.getUser()?.given_name"
                @logout="$authenticationContext.logout()"
              />
            </div>
          </div>
        </div>
      </template>
      <template #default>
        <div v-if="$route.name === 'trips-editor-presentation-new'"><Loader /></div>
        <div v-if="$route.name === 'trips-editor-presentation-duplicate'"><Loader /></div>
        <div v-if="!editablePresentation"><Loader /></div>
        <RouterView
          v-else
          name="content"
          :presentation="editablePresentation"
          @update:presentation="updatePresentation"
        />
      </template>
    </OneColumnWithHeader>
  </div>
</template>
